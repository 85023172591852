import React from 'react'
import './App.css';
import { StateProvider } from './components/ContextProvider'
import DataUpload from './components/DataUpload/DataUpload'
import NavBar from './components/AppBar';

function App() {

  let [initialState, setInitialState] = React.useState({
    data: { tableContent: null },
    uiState: 'Home',
    theme: { spacing: 8 },
    nodeProcessing: false,
    showOrdersTable: false,
    settings: false,
    tool: localStorage.getItem('brokrQuotesTool') || 'Rates'
  })
  const reducer = (state, action) => {
    switch (action.type) {
      case 'setUser':
        return {
          ...state,
          user: action.user
        }

      case 'openDialog':
        return {
          ...state,
          openDialog: action.payload
        }

      case 'switchTool':
        return {
          ...initialState,
          tool: state.tool == 'Rates' ? 'Plans' : 'Rates'
        }

      case 'addData':
        return {
          ...state,
          totalRows: action.newData.tableContent.body.length,
          data: action.newData,
          filename: action.filename,
          uiState: 'Preview'
        }

      case 'loadSettings':
        return {
          ...state,
          settings: action.payload.settings
        }

      case 'saveResults':
        return {
          ...state,
          resultData: action.data,
          uiState: 'Final',
          paid: false,
          processed: false,
          nodeProcessing: false
        }

      case 'savePaidResults':
        return {
          ...state,
          resultData: action.data,
          uiState: 'Final',
          paid: true,
          processed: false,
          nodeProcessing: false
        }

      case 'markDataProcessed':
        return {
          ...state,
          processed: true
        }

      case 'nodeProcessingTrue':
        return {
          ...state,
          nodeProcessing: true,
          funcOptions: action.payload.options,
          orderNumber: action.payload.orderNumber
        }

      case 'cardDeclined':
        return {
          ...state,
          cardDeclined: true,
          nodeProcessing: false
        }

      case 'toggleOrdersTable':
        return {
          ...state,
          showOrdersTable: action.payload.showOrdersTable
        }

      case 'addUserOrders':
        return {
          ...state,
          userOrders: action.data
        }

      case 'reset':
        return initialState

      default:
        return state
    }
  }

  return (
    <div className="App">
      <StateProvider initialState={initialState} reducer={reducer}>
        <NavBar />
        <DataUpload />
      </StateProvider>
    </div>
  );
}

export default App;
