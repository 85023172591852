import React from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import { useStateValue } from '../ContextProvider'
import { ExcelRenderer } from 'react-excel-renderer'
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import { downloadTemplate } from '../../actions/WPActions'
// import FileReaderNew from 'filereader'
/* const siteURL = window.location.port != '3000' ? 'https://www.transformania.com' : 'http://localhost:8100' */

const useStyles = makeStyles({
    snackbarRoot: {
        backgroundColor: '#f44336',
    },
    link: {
        color: '#fff',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    mapperRow: {
        margin: '15px 0'
    },
    mapperHeadingRow: {
        margin: '30px 0',
        fontWeight: 'bold'
    },
    formControl: {
        display: 'flex'
    },
    errorText: {
        color: '#f44336'
    },
    mapperItem: {
        display: 'grid',
        gridGap: '10px',
        gridTemplateColumns: '1fr 1fr'
    },
    dialogText: {
        display: 'flex'
    },
    dialogLink: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
});

// const empty = (data) => {
//     if (typeof (data) === 'number' || typeof (data) === 'boolean') {
//         return false;
//     }
//     if (typeof (data) === 'undefined' || data === null) {
//         return true;
//     }
//     if (typeof (data.length) != 'undefined') {
//         return data.length === 0;
//     }
//     var count = 0;
//     for (var i in data) {
//         if (data.hasOwnProperty(i)) {
//             count++;
//         }
//     }
//     return count === 0;
// }

const Dropzone = () => {
    const classes = useStyles();
    const [{ data, tool, settings }, dispatch] = useStateValue();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [key, setKey] = React.useState(0);
    const [message, setMessage] = React.useState("");

    const convertBytesToMbsOrKbs = (filesize) => {
        var size = '';
        // I know, not technically correct...
        if (filesize >= 1000000) {
            size = filesize / 1000000 + ' megabytes';
        } else if (filesize >= 1000) {
            size = filesize / 1000 + ' kilobytes';
        } else {
            size = filesize + ' bytes';
        }
        return size;
    }

    const fileHandler = (file) => {

        const requiredFields = () => {
            console.log(tool)
            switch (tool) {
                case 'Rates':
                    return [
                        'Plan Id',
                        'Rating Area',
                        '21 Year old rate'
                    ]
                case 'Plans':
                    return [
                        'Insurance Company', // 'Anthem', // lookup in providers table to get provider_id
                        'Plan Name', // 'Anthem Platinum PPO 10/0%/3500 (5L6R)', // name
                        'Plan Code', // '5L6R', // code
                        'Network Type', // 'PPO', 
                        'Metal Tier/Funding', // 'Platinum',
                        'Doctor Visit', // 10, // primary_care_in (convert to string with $ at the front)
                        'Specialist Visit', // 30, // specialist_in (convert to string with $ at the front)
                        'Urgent Care', // 30, // urgent_care_in (convert to string with $ at the front)
                        'Emergency Room', // 350, // emergency_room (convert to string with $ at the front)
                        'Hospital Stay', // 350, // hospital_in (convert to string with $ at the front)
                        'Coinsurance', // 0, // coinsurance_paid_in (convert to string with $ at the front)
                        'Prescription Drugs', // '$10/$40/25%/25%', // rx_plan && break up by / if exactly 3 exist and put in generic_drug_in / preferred_brand_in / nonpreferred_brand_in / specialty_drug_in
                        'Deductible - Indiv / Family', // '$0 / $0', // split by / if only 1 and trim and put in deductible_person_in / deductible_family_in
                        'Out-of-Pocket Max - Indiv / Family' // '$3,500 / $7,000', // split by / if only 1 and trim and put in oop_person_in / oop_family_in
                    ]
            }
        }

        const hasRequiredFields = (headings) => {
            let result = true
            const requiredFieldsArr = requiredFields()
            console.log(requiredFieldsArr)
            for (var requiredField of requiredFieldsArr) {
                let found = false
                for (var heading of headings) {
                    if (heading === requiredField) {
                        found = true
                    }
                }
                if (found === false) {
                    result = false
                }
            }
            return result
        }

        const trimHeading = heading => {
            console.log(heading)
            return heading.toString().trim()
        }

        const getHeadings = (resp) => {
            if (tool == 'Rates') return resp.rows[2].map(trimHeading)

            if (tool == 'Plans') {
                let firstCol = resp.rows.map(row => row[0])
                console.log(firstCol)
                return firstCol
            }
        }

        const transpose = matrix => matrix[0].map((col, i) => matrix.map(row => row[i]));

        //just pass the file as parameter
        ExcelRenderer(file, (err, resp) => {
            console.log(resp)
            if (err) {
                console.log(err);
            }
            else {
                setSnackbarOpen(false)
                const excelHeadings = getHeadings(resp)

                if (!hasRequiredFields(excelHeadings)) {
                    setKey(key + 1)
                    setMessage('Sorry, your file does not have all the required headings')
                    setSnackbarOpen(true)
                    return
                } else {
                    var excelData = tool == 'Rates' ? resp.rows.slice(3) : transpose(resp.rows.slice(13)).slice(1)
                    console.log(excelData)
                    let processedCols = excelHeadings.map((col) => {
                        let newCol = {
                            title: col,
                            field: col
                        }
                        return newCol
                    })
                    console.log(processedCols)
                    if (tool == 'Plans') processedCols = processedCols.slice(13)
                    console.log(processedCols)
                    let processedRows = excelData.filter(row => {
                        let validRow = false
                        row.forEach((val) => {
                            if (val != null) {
                                validRow = true
                            }
                        })
                        return validRow
                    }).map((row) => {
                        let fieldArr = processedCols.map(col => col.field)
                        let rowObj = {}
                        fieldArr.forEach((field, i) => {
                            rowObj[field] = row[i]
                        })
                        return rowObj
                    })
                    console.log(processedRows)
                    dispatch({
                        type: 'addData',
                        newData: { tableContent: { cols: processedCols, body: processedRows } },
                        filename: file.name
                    })
                }
            }
        });

    }

    /**
     * CSV parser if needed in orpheus-transform repo
     */

    if (data.results) {
        return null
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    return (
        <>
            <DropzoneArea
                key={key}
                onDrop={(files => {
                    if (files.length) {
                        console.log(files)
                        let reader = new FileReader();
                        // let dataType = file.type
                        reader.onload = (e) => {
                            console.log('hello')
                            // let info = e.target.result
                            // Excel Handler
                            fileHandler(files[0])
                            // CSV Handler - currently disabled
                            // parseFile(info, dataType)
                        }
                        reader.readAsDataURL(files[0]);
                    }
                })}
                onDelete={() => setKey(key + 1)}
                getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
                    var message = 'File ' + rejectedFile.name + ' was rejected. ';
                    if (!acceptedFiles.includes(rejectedFile.type)) {
                        message += 'Please only upload an .xls or .xlsx file.';
                    }
                    if (rejectedFile.size > maxFileSize) {
                        message += 'File is too big. Size limit is ' + convertBytesToMbsOrKbs(maxFileSize) + '. ';
                    }
                    return message;
                }}
                filesLimit={1}
                dropzoneText={'Drop an XLS or XLSX file here or click to browse...'}
                acceptedFiles={['.xls', '.xlsx', '.csv']}
                maxFileSize={20000000}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackbarOpen}
                autoHideDuration={12000}
                onClose={handleClose}
                message={message}
                classes={{ root: classes.snackbarRoot }}
                ContentProps={{
                    classes: {
                        root: classes.snackbarRoot
                    }
                }}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="primary" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </>
    )
}

export default Dropzone;