import jwtDecode from 'jwt-decode';

const nodeURL = process.env.REACT_APP_NODEURL 

const setSession = ({ accessToken, user }) => {
    if (accessToken && user) {
        console.log('setting token and user in local storage')
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('user', JSON.stringify(user));
    } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
    }
};

export const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
};

export const doLogin = (email, password, successCallback = () => { }, failureCallback = () => { }) => {
    const body = {
        email: email,
        password: password
    }

    const opts = {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    }

    return fetch(`${nodeURL}/api/auth/login`, opts)
        .then(res => res.json())
        .then(myJson => {
            const { token, user } = myJson;

            setSession({
                accessToken: token,
                user: user
            });
            successCallback(myJson)
        })
        .catch(err => failureCallback(err))

}

export const reLogin = async (successCallback = () => { }, failureCallback = () => { }) => {
    try {
        const accessToken = window.localStorage.getItem('accessToken');
        const user = JSON.parse(window.localStorage.getItem('user'));

        if (accessToken && isValidToken(accessToken) && user) {
            console.log('valid JWT token exists, checking it against server')
            const opts = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": accessToken
                }
            }

            return fetch(`${nodeURL}/api/auth/user?user_id=${user.id}`, opts)
                .then(res => res.json())
                .then(myJson => {
                    console.log(myJson)
                    successCallback(myJson)
                })
                .catch(err => {
                    failureCallback(err)
                })
        } else {
            failureCallback("No valid user info in local storage")
        }
    } catch (err) {
        console.error(err);
        failureCallback(err)
    }
};
