import React, { useState, useEffect } from 'react'
import { DialogContent, Typography } from '@material-ui/core'
import { useStateValue } from '../ContextProvider'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { doLogin, reLogin } from '../../actions/authActions'

const wpRestURL = window.location.port !== '3000' ? window.__SITE_URL__ : 'http://localhost:8100'

const useStyles = makeStyles(() => ({
    close: {
        margin: `10px`,
        position: `absolute`,
        right: 0
    }
}));

const LoginDialog = (props) => {
    const classes = useStyles()
    const [{ openDialog }, dispatch] = useStateValue()
    const [state, setState] = useState({
        email: '',
        password: '',
    })
    const [validationError, setValidationError] = React.useState(false)
    const [authenticating, setAuthenticating] = React.useState(false)

    const handleChange = event => {
        if (event.target.name === "acceptTerms") {
            setState({ ...state, [event.target.name]: event.target.checked });
        } else {
            setState({ ...state, [event.target.name]: event.target.value });
        }
    }

    const submitOnEnterKey = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleLogin()
        }
    }

    const handleInputFocus = event => {
        setState({ ...state, focus: event.target.name });
    }

    const isValidEmail = email => {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true
        }
        return false
    }

    const handleLogin = () => {
        console.log('login clicked')
        const { email, password } = state

        if (!email || !password) {
            setValidationError("Please complete all fields!")
            return
        }

        if (!isValidEmail(email)) {
            setValidationError("Invalid email address!")
            return
        }

        setValidationError(false)
        setAuthenticating(true)

        doLogin(email, password, (responseJson) => {
            console.log('response received after logging in user', responseJson)
            if (!responseJson.hasOwnProperty('token')) {
                setValidationError("Login unsuccessful!")
                setAuthenticating(false)
                return
            }
            setAuthenticating(false)
            dispatch({ type: 'openDialog', payload: false })
            dispatch({ type: 'setUser', user: responseJson.user })
            // props.setOpenLoginDialog(false)
        }, (error) => {
            console.log(error)
            setAuthenticating(false)
            setValidationError('Login unsuccessful!')
        })
    }

    useEffect(() => {

        setAuthenticating(true)
        reLogin((responseJson) => {
            setAuthenticating(false)
            dispatch({ type: 'openDialog', payload: false })
            dispatch({ type: 'setUser', user: responseJson })
        }, (error) => {
            console.log()
            setAuthenticating(false)

        })
    }, [])

    return (
        <Dialog
            open={(props.open || openDialog) ? true : false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {!authenticating && (<IconButton
                key="close"
                aria-label="close"
                color="primary"
                className={classes.close}
                onClick={() => {
                    props.handleClose()
                    setTimeout(() => dispatch({ type: 'openDialog', payload: false }), 50)
                }}
            >
                <CloseIcon />
            </IconButton>)}
            <DialogTitle>
                {authenticating ? 'Please wait a minute' : 'Welcome back, please log in with your admin account'}
            </DialogTitle>
            <DialogContent>
                {authenticating && (<Typography variant="body2" style={{ marginBottom: 20 }}>Authenticating...</Typography>)}
                {validationError ? <Typography variant="body2" color="error" style={{ marginBottom: 20 }}>{validationError}</Typography> : null}
                {!authenticating ?
                    (<>
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            value={state.email}
                            onChange={handleChange}
                            onFocus={handleInputFocus}
                            variant="outlined"
                            style={{ marginBottom: 20 }}
                        />
                        <TextField
                            fullWidth
                            label="Password"
                            name="password"
                            type="password"
                            value={state.password}
                            onChange={handleChange}
                            onKeyPress={submitOnEnterKey}
                            onFocus={handleInputFocus}
                            variant="outlined"
                            style={{ marginBottom: 20 }}
                        />
                        {/* <Button
                            onClick={() => window.location.href = wpRestURL + '/user-password-lost/'}
                            color="primary"
                        >
                            Forgot Password
                            </Button> */}
                    </>
                    ) : <CircularProgress />}
            </DialogContent>
            {!authenticating && (<DialogActions>
                <Button onClick={handleLogin} color="primary">
                    Continue
                </Button>
            </DialogActions>)}
        </Dialog>
    )
}
export default LoginDialog