import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core'
import Dropzone from './Dropzone'
import { useStateValue } from '../ContextProvider'
import CircularProgress from '@material-ui/core/CircularProgress'
// import { createOrder, saveData, verifyRecaptcha } from '../../actions/WPActions'
import Typography from '@material-ui/core/Typography';
// import ReCAPTCHA from "react-google-recaptcha";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from 'moment'
import { DataGrid } from '@material-ui/data-grid';
import { checkExistingRates, importRates } from '../../actions/rateActions'
import { checkExistingPlans, importPlans } from '../../actions/planActions'

const useStyles = makeStyles(theme => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    button: {
        margin: `10px`
    },
    reCaptcha: {
        margin: '10px auto'
    },
    formControl: {
        margin: '10px'
    }
}))


const DataUploadList = () => {

    const [{ data, user, tool, filename, settings, nodeProcessing, totalRows, socket }, dispatch] = useStateValue()
    const classes = useStyles()
    const [uploading, setUploading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [showingResults, setShowingResults] = React.useState(false)
    const [year, setYear] = React.useState(moment().format('YYYY'))
    const [quarter, setQuarter] = React.useState(1)
    const [state, setState] = React.useState('VA')
    const [rows, setRows] = React.useState([])
    const [cols, setCols] = React.useState([])
    const [checking, setChecking] = React.useState(false)

    const handleChangeYear = (event) => setYear(event.target.value);
    const handleChangeQuarter = (event) => setQuarter(event.target.value);
    const handleChangeState = event => setState(event.target.value)

    const submitFile = (skip_existing) => {
        setUploading(true)

        let importRows = tool == 'Rates' ? importRates : importPlans

        let body = {
            rows: data.tableContent.body,
            skip_existing: skip_existing
        }

        if (tool == 'Rates') {
            body.year = year
            body.quarter = quarter
        }

        if (tool == "Plans") {
            body.state = state
        }

        importRows(body, (myJson) => {
            console.log(myJson)
            let newRows = myJson.map((row, i) => {
                return {
                    ...row,
                    id: i
                }
            })
            setRows(newRows)
            let newCols = data.tableContent.cols.filter(el => typeof el == 'object').map(el => {
                return {
                    ...el,
                    width: 150
                }
            })
            newCols.push({ field: 'Status', title: 'Status', width: 120 })
            newCols.push({ field: 'Note', title: 'Note', width: 1000 })
            // console.log(newCols)
            setCols(newCols)
            setUploading(false)
            setShowingResults(true)
        }, (err) => {

        })
    }

    useEffect(() => {
        if (data.tableContent) {
            setChecking(true)

            let checkExistingRows = tool == 'Rates' ? checkExistingRates : checkExistingPlans

            let body = {
                rows: data.tableContent.body
            }

            if (tool == 'Rates') {
                body.year = year
                body.quarter = quarter
            }

            if (tool == "Plans") {
                body.state = state
            }

            checkExistingRows(body, (myJson) => {
                console.log(myJson)
                let newRows = myJson.map((row, i) => {
                    return {
                        ...row,
                        id: i
                    }
                })
                setRows(newRows)
                let newCols = data.tableContent.cols.filter(el => typeof el == 'object').map(el => {
                    return {
                        ...el,
                        width: 150
                    }
                })
                newCols.push({ field: 'Status', title: 'Status' })
                newCols.push({ field: 'Note', title: 'Note', width: 600 })
                // console.log(newCols)
                setCols(newCols)
                setChecking(false)
            }, (err) => {

            })
        }
    }, [data, year, quarter])

    return (
        <Grid container>
            <Grid item lg={12} md={12} sm={12}>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>{!data.tableContent ? `Ready To Import ${tool.replace('s', '')} Data?` : "Your File"}</Typography>
                {!data.tableContent && (<Typography variant="body1" gutterBottom>In a few easy steps, your {tool.replace('s', '').toLowerCase()} data will be imported! Begin by uploading your spreadsheet of data below:</Typography>)}
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
                {!data.tableContent ? <Dropzone settings={settings} /> :
                    <Grid
                        container
                        margin={30}
                        direction="column"
                        justify="center"
                        alignItems="stretch"
                    >
                        {nodeProcessing || uploading ? <CircularProgress /> : (<>
                            <Typography variant="body1" gutterBottom>File Name: {filename}</Typography>
                            <Typography variant="body1" gutterBottom>Total Rows: {totalRows}</Typography>
                            {error && (<Typography color="error" gutterBottom>{error}</Typography>)}
                            {tool == 'Rates' && (<div className={classes.formRow}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={year}
                                        onChange={handleChangeYear}
                                    >
                                        <MenuItem value={parseInt(moment().format('YYYY'))}>{parseInt(moment().format('YYYY'))}</MenuItem>
                                        <MenuItem value={parseInt(moment().format('YYYY')) + 1}>{parseInt(moment().format('YYYY')) + 1}</MenuItem>
                                        <MenuItem value={parseInt(moment().format('YYYY')) + 2}>{parseInt(moment().format('YYYY')) + 2}</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">Quarter</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={quarter}
                                        onChange={handleChangeQuarter}
                                    >
                                        <MenuItem value={1}>1st</MenuItem>
                                        <MenuItem value={2}>2nd</MenuItem>
                                        <MenuItem value={3}>3rd</MenuItem>
                                        <MenuItem value={4}>4th</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>)}
                            {tool == 'Plans' && (<div className={classes.formRow}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={state}
                                        onChange={handleChangeState}
                                    >
                                        <MenuItem value={'VA'}>VA</MenuItem>
                                        <MenuItem value={'MD'}>MD</MenuItem>
                                        <MenuItem value={'DC'}>DC</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>)}
                            <div style={{ height: 300, width: '100%' }}>
                                {checking ? <CircularProgress /> : <DataGrid rows={rows} columns={cols} />}
                            </div>
                            {!showingResults && (<><Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() => submitFile(false)}
                            >
                                Import (Overwrite Existing)
                            </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => submitFile(true)}
                                >
                                    Import (Skip Existing)
                                </Button>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    onClick={() => dispatch({ type: 'resetApp' })}
                                >
                                    Cancel
                                </Button></>)}
                        </>)}
                    </Grid>}
            </Grid >
        </Grid >
    )
}

export default DataUploadList