import React from 'react'
import { AppBar, Toolbar, IconButton, Typography, Button } from '@material-ui/core'
import { Repeat } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles';
import { useStateValue } from '../ContextProvider';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: theme.spacing(4)
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        // flexGrow: 1,
    },
    login: {
        marginLeft: 'auto'
    }
}));

function NavBar() {
    const classes = useStyles();
    const [{ tool, user }, dispatch] = useStateValue()

    return (
        <AppBar position="static" className={classes.root} >
            <Toolbar>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => {
                    localStorage.setItem('brokrQuotesTool', tool == 'Rates' ? 'Plans' : 'Rates')
                    dispatch({ type: 'switchTool' })
                }}>
                    <Repeat />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Importing {tool}
                </Typography>
                {user && <Typography variant="h6" className={classes.login}>
                    {"Logged in as: " + user.email}
                </Typography>}
            </Toolbar>
        </AppBar>
    )
}

export default NavBar
