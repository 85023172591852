import React from 'react'
import { Grid, Paper, Button } from '@material-ui/core'
import DataUploadList from './DataUploadList'
import { useStateValue } from '../ContextProvider'
import { makeStyles } from '@material-ui/core/styles';
import LoginDialog from '../Login'
// import { downloadTemplate } from '../../actions/WPActions'

const DataUpload = () => {
    const useStyles = makeStyles((theme) => ({
        actions: {
            display: 'flex',
            flexWrap: 1,
            padding: '10px 0'
        },
        actionButton: {
            flexGrow: 1
        },
        center: {
            margin: '0 auto'
        },
        root: {
            display: 'flex',
            justifyContent: 'center',
            '& > *': {
                margin: theme.spacing(1)
            },
        },
        paper: {
            minWidth: 300,
            padding: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column'
        },
        buttonSpace: {
            marginTop: theme.spacing(1)
        }
    }));

    const classes = useStyles();
    const [{ uiState, user, nodeProcessing, showOrdersTable, data }, dispatch] = useStateValue();

    const [openLoginDialog, setOpenLoginDialog] = React.useState(true)

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }

        setOpenLoginDialog(false)
    }

    if (!user) {
        console.log('no user is logged in, showing login dialog')
        return <LoginDialog open={openLoginDialog} handleClose={handleClose} />
    }

    if (uiState === 'Final' || nodeProcessing || showOrdersTable === true) return null

    return (
        <Grid item className={classes.center} lg={data.tableContent != null ? 12 : 4} md={12} sm={12}>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <DataUploadList p={2} />
                    {!data.tableContent ? <div className={classes.actions}>
                        <Button
                            color="secondary"
                            onClick={null} // { () => downloadTemplate()}
                            className={[classes.left, classes.actionButton].join(" ")}
                        >
                            Download template
                        </Button>
                    </div> : null}
                </Paper>
            </div>
        </Grid>
    )
}
export default DataUpload