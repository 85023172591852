import { isValidToken } from './authActions'
const nodeURL = process.env.REACT_APP_NODEURL

export const checkExistingRates = (body, successCallback = () => { }, failureCallback = () => { }) => {
    const accessToken = window.localStorage.getItem('accessToken');
    const user = JSON.parse(window.localStorage.getItem('user'));

    if (accessToken && isValidToken(accessToken) && user) {

        const opts = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-auth-token": accessToken
            },
            body: JSON.stringify(body)
        }

        return fetch(`${nodeURL}/api/rates/check_existing`, opts)
            .then(res => res.json())
            .then(myJson => {
                successCallback(myJson)
            })
            .catch(err => failureCallback(err))
    }
}

export const importRates = (body, successCallback = () => { }, failureCallback = () => { }) => {
    const accessToken = window.localStorage.getItem('accessToken');
    const user = JSON.parse(window.localStorage.getItem('user'));

    if (accessToken && isValidToken(accessToken) && user) {

        const opts = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-auth-token": accessToken
            },
            body: JSON.stringify(body)
        }

        return fetch(`${nodeURL}/api/rates/import`, opts)
            .then(res => res.json())
            .then(myJson => {
                successCallback(myJson)
            })
            .catch(err => failureCallback(err))
    }
}
